import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const HeaderWrapper = styled.header`
  width: 100%;

  @media (max-width: 991px) {
    .transparent {
      .navbar_search {
        display: none;
      }
    }
  }

  @media (max-width: 667px) {
    nav {
      &:not(.transparent) {
        a {
          > h3 {
            // display: none;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 989px) {
    nav {
      &:not(.transparent) {
        a {
          > h3 {
            // display: none;
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 580px) {
    nav {
      &:not(.transparent) {
        a {
          > h3 {
            // display: none;
            font-size: 13px;
          }
        }
      }
    }
  }

  .sticky-outer-wrapper {
    nav {
      &.is_transparent {
        > div > div {
          > svg {
            width: 20px;
            position: absolute;
          }

          a {
            img {
              opacity: 0;
            }

            h3 {
              color: ${themeGet('color.1', '#ffffff')};
            }
          }
        }

        .ant-menu:not(.active) {
          li {
            a {
              &.active {
                color: ${themeGet('color.1', '#ffffff')};
              }
              &:hover {
                color: ${themeGet('color.1', '#ffffff')};
              }
            }
          }
        }
      }

      &.transparent {
        > div > div {
          > svg {
            width: 20px;
            position: absolute;
          }

          a {
            img {
              opacity: 0;
              display: none;
            }

            h3 {
              color: ${themeGet('color.1', '#ffffff')};
            }
          }
        }
      }
    }

    &.isHeaderSticky {
      nav {
        &.is_transparent {
          background-color: ${themeGet('color.1', '#ffffff')};
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

          > div > div {
            > svg {
              display: none;
            }

            a {
              img {
                opacity: 1;
              }

              h3 {
                color: ${themeGet('primary.0', '#008489')};
              }
            }
          }

          .ant-menu {
            li {
              a {
                color: ${themeGet('text.0', '#2C2C2C')};
                &:hover {
                  color: ${themeGet('primary.0', '#008489')};
                }
                &.active {
                  color: ${themeGet('primary.0', '#008489')};
                  border-bottom: 3px solid ${themeGet('primary.0', '#008489')};
                }
              }
            }
          }

          .auth_menu {
            .ant-menu {
              li {
                &:last-child {
                  a {
                    color: ${themeGet('color.1', '#ffffff')};
                  }
                }
              }
            }
          }
        }

        &.transparent {
          background-color: ${themeGet('color.1', '#ffffff')};
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

          > div > div {
            > svg {
              display: none;
            }

            a {
              img {
                opacity: 1;
              }

              h3 {
                color: ${themeGet('primary.0', '#008489')};
              }
            }
          }

          .hamburg-btn {
            > span {
              background-color: ${themeGet('text.0', '#2C2C2C')};
            }
          }
        }
      }
    }
  }
`;

export const MobileNavbar = styled.nav`
  display: flex;
  padding: 0 20px; \\left of bean 
  align-items: center;
  justify-content: space-between;
  min-height: 82px;
  width: 100%;

  &.default {
    border-bottom: 1px solid ${themeGet('border.3', '#E6E6E6')};
    background-color: ${themeGet('color.1', '#ffffff')};
  }

  .hamburg-btn {
    border: 0;
    padding: 0;
    width: auto;
    height: auto;
    background-color: transparent;

    > span {
      display: block;
      width: 20px;
      height: 2px;
      margin: 4px 0;
      border-radius: 5px;
      background-color: ${themeGet('text.0', '#2C2C2C')};
      transition: all 0.3s ease;
    }

    &:hover,
    &.active {
      background-color: transparent;

      > span {
        width: 23px;
        background-color: ${themeGet('primary.0', '#008489')};
      }
    }

    &::after {
      display: none;
    }
  }

  &.transparent {
    position: fixed;
    z-index: 9999;

    .hamburg-btn {
      > span {
        background-color: ${themeGet('color.1', '#ffffff')};
      }
    }
  }
`;

export const LogoArea = styled.div`
  display: flex;
  align-items: center;

  > a {
    flex-shrink: 0;
    margin-right: 27px;

    @media (max-width: 480px) {
      margin-right: 20px;
      font-size: 15px;
    }

    @media (max-width: 980px) {
      // margin-right: 100px;
    }

    img {
      height: 28px;
      max-width: inherit;
    }
  }
`;

export const CloseDrawer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 15px 12px;

  > button {
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 38px;
    line-height: 1;
    height: auto;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: ${themeGet('text.1', '#909090')};
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      color: ${themeGet('text.0', '#2C2C2C')};
    }
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  padding: 25px 35px;
  align-items: center;
  background-color: ${themeGet('color.2', '#F7F7F7')};
`;

export const AvatarImage = styled.div`
  flex-shrink: 0;
  margin-right: 15px;

  img {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const AvatarInfo = styled.div`
  h3 {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  a {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: 0;
      color: ${themeGet('primary.0', '#008489')};
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 50px;
  justify-content: flex-end;
  background-color: ${themeGet('color.2', '#F7F7F7')};
  border-radius: 3px 0 0 3px;
  height: 35px;

  @media (max-width: 991px) {
    // margin-top: 20px;
    // flex-direction: column;
  }

  @media (max-width: 480px) {
    // margin-top: 20px;
  }

  /* submit button style */
  button[type='submit'].ant-btn {
    max-width: 30px;
    height: 35px;
    color: ${themeGet('color.1', '#ffffff')};
    background-color: ${themeGet('primary.0', '#008489')};
    border-radius: 0 3px 3px 0;
    border: 0;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;

    @media (max-width: 991px) {
      // min-width: 100%;
      border-radius: 0 0 3px 3px;
      height: 35px;
    }

    @media (max-width: 480px) {
      height: 35px;
    }

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  hight: 35px;
  background-color: ${themeGet('color.2', '#F7F7F7')};

  .target {
    right: 10px;
    left: auto;

    @media (max-width: 480px) {
      right: 25px;
    }
  }

  /* map auto complete style */
  .map_autocomplete {
    width: 100%;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 35px;
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 0;
      left: auto;
    }

    @media (max-width: 991px) {
      &::after {
        top: 10px;
        height: 35px;
        width: 100%;
      }
    }

    @media (max-width: 480px) {
      &::after {
        top: 10px;
        height: 35px;
        width: 100%;
      }
    }

    input {
      border: 0;
      font-size: 15px;
      font-weight: 400;
      border-radius: 0;
      height: 35px;
      padding: 0 60px 0 10px;
      background: transparent;
      color: ${themeGet('text.0', '#2C2C2C')};

      @media (max-width: 480px) {
        height: 30px;
        padding: 0 30pxs 0 36px;
      }

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: ${themeGet('text.0', '#2C2C2C')};
      }
    }
    /* Styling for the suggestion list */
    .suggestions-list {
      position: absolute; 
      width: 100%; 
      background-color: white;
      // border: 1px solid #ccc;
      border-top: none; 
      list-style: none;
      padding: 0;
      margin: 0;
      z-index: 10; 
      border-radius: 3px; /* Add rounded corners */
      overflow: hidden; /* Prevent the icon from overflowing */
    }

    .suggestions-list li {
      padding: 10px;
      cursor: pointer;
      display: flex; /* Use flexbox for layout */
      align-items: center; /* Align icon and text vertically */
    }

    .suggestions-list li:hover,
    .suggestions-list .suggestions-list-item.active {
      background-color: #f5f5f5;
    }
  }

  /* view with popup component style */
  .view_with__popup {
    max-width: 100%;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 10px);
      right: auto;
      left: 0;
      z-index: 1;
      background-color: ${themeGet('border.3', '#E6E6E6')};

      @media (max-width: 991px) {
        top: 0;
        bottom: auto;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
      }

      @media (max-width: 480px) {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    /* popu handler style */
    .popup_handler {
      width: calc(100% - 14px);
      margin-left: 14px;

      button.ant-btn {
        min-width: auto;
        width: 100%;
        height: 60px;
        border: 0;
        padding: 0 30px;
        border-radius: 0;
        box-shadow: none;
        font-weight: 400;
        font-size: 15px;
        color: ${themeGet('text.0', '#2C2C2C')};
        background-color: ${themeGet('color.2', '#F7F7F7')};

        @media (max-width: 991px) {
          text-align: left;
          padding: 0 25px 0 30px;
        }

        @media (max-width: 480px) {
          height: 47px;
          padding: 0 20px 0 25px;
        }

        &::after {
          display: none;
        }
      }
    }

    &.active {
      .popup_container {
        margin-top: 0;
        padding: 22px;
        box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);

        @media (max-width: 991px) {
          margin-top: 0;
        }

        @media (max-width: 480px) {
          min-width: auto;
        }
      }
    }
  }

  /* icon style */
  > svg {
    position: absolute;
    z-index: 1;
    top: auto;
    &.map-marker,
    &.calendar,
    &.user-friends {
      left: 15px;
      right: auto;
      fill: ${themeGet('primary.0', '#008489')};
    }

    &.calendar {
      @media (max-width: 480px) {
        width: 14px;
        height: 14px;
      }
    }

    &.user-friends {
      width: 17px;
      height: 17px;
      @media (max-width: 480px) {
        width: 16px;
        height: 16px;
      }
    }

    &.caret-down {
      right: 12px;
      left: auto;
      fill: ${themeGet('text.1', '#909090')};

      @media (max-width: 991px) {
        right: 20px;
      }

      @media (max-width: 991px) {
        right: 15px;
      }
    }
  }
`;

// export const NavbarSearchWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   position: relative;
//   max-width: 430px;
//   width: 100%;
//   margin-left: 30px;
//   // Navbar logo space edited
//   @media (max-width: 1200px) {
//     max-width: 320px;
//   }
//   @media (max-width: 960px) {
//     padding-right: 15px; \\between search text and magny
//     box-sizing: border-box;
//     margin-left: 10px;
//   }

//   @media (max-width: 480px) {
//     padding-right: 15px; \\between search text and magny
//     box-sizing: border-box;
//     margin-left: 8px; \\ margin from logo
//   }

//   .map_autocomplete {
//     width: 100%;
//     position: relative;
    
//     /* Styling for the suggestion list */
//     .suggestions-list {
//       position: absolute; 
//       width: 100%; 
//       background-color: white;
//       // border: 1px solid #ccc;
//       border-top: none; 
//       list-style: none;
//       padding: 0;
//       margin: 0;
//       z-index: 10; 
//       border-radius: 3px; /* Add rounded corners */
//       overflow: hidden; /* Prevent the icon from overflowing */
//     }

//     .suggestions-list li {
//       padding: 10px;
//       cursor: pointer;
//       display: flex; /* Use flexbox for layout */
//       align-items: center; /* Align icon and text vertically */
//     }

//     .suggestions-list li:hover {
//       background-color: #f5f5f5;
//     }
//   }

//   }

//   input {
//     font-size: 15px;
//     font-weight: 600;
//     padding-left: 15px;
//     padding-right: 30px;
//     border-radius: 4px;
//     color: ${themeGet('text.0', '#2C2C2C')};
//     border: 1px solid ${themeGet('border.0', '#EBEBEB')};
//     background-color: ${themeGet('color.2', '#F7F7F7')};
//     height: 40px;
//     width: 100%;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     transition: all 0.3s ease;
//     @media (max-width: 1200px) {
//       height: 40px;
//     }

//     @media (max-width: 480px) {
//       font-size: 13px;
//       padding-left: 10px;
//       height: 35px;
//     }

//     &::placeholder {
//       color: rgb(72, 72, 72);
//     }

//     &:hover,
//     &:focus {
//       border-color: ${themeGet('primary.0', '#008489')};
//     }
//   }

//   > svg {
//     position: absolute;
//     z-index: 1;
//     top: auto;
//     right: 25px;
//     width: 20px;
//     height: 20px;

//     @media (max-width: 480px) {
//       right: 25px;
//     }
//   }
// `;

export default HeaderWrapper;
