import React from 'react';
import Logo from 'components/UI/Logo/Logo';
import Footers from 'components/Footer/Footer';
import FooterMenu from './FooterMenu';

const Footer = () => {
  return (
    <Footers
      logo={
        <Logo
          // withLink
          // linkTo="/"
          src="/images/bean-logo.svg"
          title="Specialty Coffee Finder"
        />
      }
      menu={<FooterMenu />}
      copyright={`Copyright @ ${new Date().getFullYear()} Specialty Coffee Finder.`}
    />
  );
};

export default Footer;
