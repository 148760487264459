import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer } from 'antd';
import Logo from 'components/UI/Logo/Logo';
import Text from 'components/UI/Text/Text';
import TextLink from 'components/UI/TextLink/TextLink';
import Navbar from 'components/Navbar/Navbar';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import { AGENT_PROFILE_PAGE } from 'settings/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';

const avatarImg = `http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png`;
const LogoIcon = () => (
  //logo size changed
  <svg width="35" height="25" viewBox="0 0 40 46" fill="none">
    <g>fill="none" xmlns="http://www.w3.org/2000/svg"
    <path fillRule="evenodd" clipRule="evenodd" d="M15.2381 5.3874C17.5653 12.8204 18.0649 20.6308 15.7814 27.8074C13.9454 33.5779 13.5673 39.6932 14.6541 45.612C6.31179 43.6082 0 34.2503 0 23.0087C0 12.0949 5.94898 2.95652 13.9295 0.599218C14.2656 2.12115 14.7319 3.77046 15.2381 5.3874ZM18.9305 0.0388522C19.2082 1.20868 19.5794 2.51882 20.0098 3.8935C22.5623 12.0464 23.2145 20.9368 20.5461 29.3234C18.8367 34.6958 18.5897 40.4142 19.8128 45.8935C28.9026 44.7319 36 34.9294 36 23.0087C36 10.7049 28.4392 0.65754 18.9305 0.0388522Z" fill="white"/>
    </g>
  </svg>
);

export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType = location.pathname === '/' ? 'transparent' : 'default';

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === 'transparent' ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/bean-logo.svg"
                  title="Specialty Coffee Finder"
                />
              </>
            }
            //Remove header tabs
            // navMenu={<MainMenu />}
            // Remove Signup and login from header
            // authMenu={<AuthMenu />}
            // isLogin={loggedIn}
            // avatar={<Logo src={avatarImg} />}
            // profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            // headerType={headerType}
            searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                {headerType === 'transparent' && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  //Remove additinal logo tha appeared in mobile view (transparent)
                  src="/images/bean-logo.svg"
                  title="Specialty Coffee Finder"
                />
              </>
              <NavbarSearch />
            </LogoArea>
            {/* <Button
              className={`hamburg-btn ${state ? 'active' : ''}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button> */}
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo src={avatarImg} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content="Nova Scotia" />
                    <TextLink
                      link={AGENT_PROFILE_PAGE}
                      content="View Profile"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
