import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { supportedCities } from './cities'; 
import Fuse from 'fuse.js';

const SearchInput = (props) => {
  const { getInputValue } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [isSearching, setIsSearching] = useState(false);

  const searchBarRef = useRef(null); // Ref for the entire search bar
  const listRef = useRef(null); // Ref for the suggestions list

  const fuse = new Fuse(supportedCities, {
    keys: ['name', 'country'], // Keys to search against
    threshold: 0.3,           // Threshold for matching (0 = exact match, 1 = very fuzzy match)
    isCaseSensitive: false,
    ignoreLocation: true,
    includeMatches: true,
    shouldSort: true,
  });

  const formatCity = (city) => {
    return `${city.name}, ${city.country || ''}`;
  };

  // Click outside handler
  const handleClickOutside = (event) => {
    if (searchBarRef.current && (!searchBarRef.current?.contains(event.target) || listRef.current?.contains(event.target))) {
      // Delay clearing the suggestions to allow handleSuggestionClick to finish
      setTimeout(() => {
        setSuggestions([]);
      }, 300); // Adjust the delay as needed (e.g., 200ms)
    } 
  };

  useEffect(() => {
    const searchTermLower = searchTerm.toLowerCase()
    // Handle empty search results
    if (searchTermLower === '') {
      setSuggestions(supportedCities.slice(0, 10)); // Show all or limited suggestions when empty
      return; // Exit early if there's no search term
    }

    const results = fuse.search(searchTermLower); 
    // Custom sorting to prioritize city names starting with exact match.
    results.sort((a, b) => {
      const aName = a.item?.name?.toLowerCase() || '';
      const bName = b.item?.name?.toLowerCase() || '';

      // Calculate custom score based on exact name match
      const aScore = aName.startsWith(searchTermLower) ? 0 : 1;
      const bScore = bName.startsWith(searchTermLower) ? 0 : 1;

      // If scores are the same, use Fuse.js relevance
      return aScore - bScore || a.refIndex - b.refIndex; // Fallback to comparing original indexes
    });
    setSuggestions(results.slice(0, 3).map(result => result.item)); 

    document.addEventListener('mousedown', handleClickOutside);
    // Clear suggestions when searchTerm changes (i.e., after selection)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      // if (searchTerm) { // Check if searchTerm is not empty to avoid clearing on initial render
      //   setSuggestions([]); 
      // }
    };
  }, [searchBarRef, listRef, searchTerm]); // Add searchBarRef to the dependency array

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleSuggestionClick = (selectedCity) => {
    setSearchTerm(formatCity(selectedCity)); // Set the selected city to the input field
    getInputValue({ searchedLocation: selectedCity }); // Pass the selected city for further processing
    setIsSearching(true);
    setSuggestions([]); // Clear the suggestions list after selection
  };

  const handleOnPressEnter = (event) => {
    if (event.key === 'Enter') {
      if (suggestions.length > 0) {
        const selectedCity = suggestions[0]; // Get the first suggestion
        handleSuggestionClick(selectedCity); // Use the same logic as clicking
        setTimeout(() => {
          setSuggestions([]);
        }, 300); // Adjust the delay as needed (e.g., 200ms)
      } else if (isSearching && searchTerm) {
        props.onSearchTrigger();
        setIsSearching(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (suggestions.length > 0) {
      switch (event.key) {
        case 'ArrowUp':
          setActiveSuggestion(prev => Math.max(prev - 1, 0));
          break;
        case 'ArrowDown':
          setActiveSuggestion(prev => Math.min(prev + 1, suggestions.length - 1));
          break;
        case 'Enter':
          if (suggestions[activeSuggestion]) {
            handleSuggestionClick(suggestions[activeSuggestion]);
            setActiveSuggestion(-1)
          }
          break;
      }
    }
  };

  return (
    <div className="map_autocomplete" ref={searchBarRef}> {/* Attach ref to the search bar container */}
      <Input
        type="text"
        value={searchTerm}
        placeholder="Search for a city"
        size="large"
        onChange={handleInputChange}
        onPressEnter={handleOnPressEnter}
        onKeyDown={handleKeyDown}
      />
      {searchTerm && suggestions.length > 0 && (
        <ul className="suggestions-list" ref={listRef}>
          {suggestions.map((city, index) => (
            <li 
              key={formatCity(city)} 
              className={index === activeSuggestion ? 'suggestions-list-item active' : 'suggestions-list-item'} 
              onClick={() => {
                handleSuggestionClick(city); 
              }}
            >
              {city.name}, {city.country} 
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const MapAutoComplete = (props) => {
  const { updateValue, onSearchTrigger } = props;
  return <SearchInput getInputValue={updateValue} onSearchTrigger={onSearchTrigger} />;
};

export default MapAutoComplete;
